<template>
  <v-data-table
    :headers="headers"
    :items="package_credits"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="850"
        >
          <!--          <template v-slot:activator="{ on, attrs }">-->
          <!-- <div class="d-flex justify-space-between"> -->
          <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
          <!--            <v-btn-->
          <!--              color="primary"-->
          <!--              dark-->
          <!--              class="mb-2"-->
          <!--              v-bind="attrs"-->
          <!--              v-on="on"-->
          <!--            >-->
          <!--              เพิ่มคะแนน-->
          <!--            </v-btn>-->
          <!-- </div> -->
          <!--          </template>-->
          <v-card style="overflow: hidden">
            <v-card-text>

              <v-card-title>
                <span class="text-h5">{{ formTitle }} </span>
              </v-card-title>

              <!--                <h5>รายการสั่งซื้อแพ็กเกจ ชำระครบจำนวนแล้ว </h5>-->
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-card>
                    <v-card-text>

                      <p>รหัสการสั่งซื้อแพ็กเกจ : {{ editedItem.mem_package_no }}</p>
                      <p>ลูกค้า : {{ editedItem.member ? editedItem.member.member_name : '-' }}</p>

                      <div>
                        การจ่ายเงิน :
                        <span v-if="editedItem.payment_type_api" class="badge badge-warning">จ่ายด้วยระบบ Api Payment </span>
                        <span v-if="editedItem.payment_type_api" class="badge badge-warning">อ้างอิงรหัสธุรกรรม:
                          {{ editedItem.payment_transaction_id }}
                        </span>
                        <span v-if="editedItem.payment_type_api"
                              class="badge badge-warning">สถานะ:
                          {{ editedItem.payment_status }}</span>
                        <span v-if="editedItem.payment_type_api" class="badge badge-warning">วันที่ระบบเช็ค: {{
                            editedItem.payment_check_at ? editedItem.payment_check_at : '-'
                          }}<br></span>


                        <span v-if="editedItem.payment_type_api == 'qrnone'"
                              class="badge badge-pill badge-warning"> QR Code<br></span>
                        <span v-if="editedItem.payment_type_api == 'card'"
                              class="badge badge-pill badge-success">  บัตรเคดิต/บัตรเดบิต
                    <img src="https://a.moneyspace.net/js/img/payment/Master_VISA_JCB_UNION.png"
                         class="jss94" component="picture or img" alt="payments" style="
    width: 60%;
"></span>
                        <span v-if="editedItem.payment_type_api == 'installment'"
                              class="badge badge-pill badge-success">ผ่อนจ่าย {{
                            editedItem.bankType
                          }} / {{
                            editedItem.endTerm
                          }} เดือน</span>
                        <span
                          v-if="editedItem.payment_type_api != 'qrnone' && editedItem.payment_type_api != 'card' && editedItem.payment_type_api != 'installment'"
                          class="badge badge-success">
                    {{ editedItem.payment_type ? editedItem.payment_type.name : '-' }}
                  </span>
                      </div>
                      <br>
                      <p>รูปแบบการชำระเงิน :
                        <span v-if="editedItem.payment == 'F'"
                              class="success-badge"
                              style="background-color:#4dbd74;color: black">เต็มจำนวน</span>
                        <span v-if="editedItem.payment != 'F'"
                              class="badge badge-pill badge-warning">ผ่อนจ่าย</span>
                      </p>
                      <p>จำนวนเงิน : {{ editedItem.total_price ? editedItem.total_price : 0 }} บาท</p>
                      <p>สถานะ:
                        <span v-if="editedItem.confirm_pay == 'Y'"
                              class="success-badge"
                              style="background-color:#4dbd74;color: black">ชำระเรียบร้อย</span>
                        <span v-if="editedItem.confirm_pay == 'W'"
                              class="warning-badge">รอการตรวจสอบ</span>
                        <span v-if="editedItem.confirm_pay == 'C'"
                              class="danger-badge">ยกเลิก</span>
                      </p>
                      <p>รายละเอียดเพิ่มเติม : {{ editedItem.can_note }}</p>
                      <p>หมายเหตุ : {{ editedItem.confirm_pay_detail }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-card>
                    <v-card-text>
                      <p>หลักฐานการชำระเงิน</p>
                      <p>วันที่ {{ editedItem.date_pay ? editedItem.date_pay : '-' }}</p>
                      <p>เวลา {{ editedItem.time_pay ? editedItem.time_pay : '-' }}</p>
                      <p>จำนวนเครดิตสินค้า {{ editedItem.amount ? editedItem.amount : 0 }}
                        (ชิ้น)</p>
                      <p v-if="editedItem.discount_promotion ">จำนวนเครดิตเงิน (คืน)
                        {{ editedItem.discount_promotion ? editedItem.discount_promotion : 0 }}
                        บาท</p>
                      <p>ผู้ดำเนินการ {{
                          editedItem.employee ? editedItem.employee.employee_name : '-'
                        }}</p>
                      <p>วัน/เวลา {{ editedItem.emp_date }} - {{ editedItem.emp_time }}</p>

                      <a
                        v-if="editedItem.slip"
                        target="_blank"
                        :href="editedItem.slip"
                      >
                        หลักฐานการชำระเงิน
                      </a>

                      <div class="mt-5">
                        <v-row>
                          <v-col cols="12" sm="12" md="6">
                            <v-btn color="primary" class="mr-2"
                                   @click="confirmOrder"
                                   :disabled="editedItem.confirm_pay =='Y'"
                                   :title="editedItem.confirm_pay =='Y' ? 'ยืนยันการสั่งซื้อไปเเล้ว/ยังไม่มีชำระเข้ามา' : 'ยืนยันการสั่งซื้อ'">
                              <i class="fa fa-check" aria-hidden="true"></i> ยืนยันการสั่งซื้อ
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-btn
                              @click="cancelOrder"
                              class="danger-badge"
                              :disabled="editedItem.confirm_pay =='Y'"
                              :title="editedItem.confirm_pay =='Y' ? 'ยืนยันการสั้งซื้อไปเเล้ว' : 'ยืนยันการสั้งซื้อ'"
                            >
                              ยกเลิกการสั่งซื้อ
                              <!--                      <i class="fa fa-ban" aria-hidden="true"></i>&nbsp;ยกเลิกการสั่งซื้อ-->
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-card>
                    <v-card-text>
                      <h2>รายการแพ็กเกจ</h2>
                      <table class="table">
                        <thead>
                        <tr>
                          <th class="text-left">ลำดับ</th>
                          <th class="text-left">ชื่อแพ็กเกจ</th>
                          <th>จำนวนเงินที่เติม(บาท)</th>
                          <th>รวมที่ต้องจ่าย (บาท)</th>
                        </tr>
                        </thead>
                        <tbody>

                        <!--                        <td style="text-align:right;color: #4dbd74"> +-->
                        <!--                          @if(!$package_detail->package_item_amt || $package_detail->package_name === 'กำหนดเติมเอง(สำหรับลูกค้าเติมเอง)')-->
                        <!--                          {{$package_detail->total}}-->
                        <!--                          @else-->
                        <!--                          {{$package_detail->package_item_amt*$package_detail->amt}}-->
                        <!--                          @endif-->

                        <!--                        </td>-->
                        <tr v-for="(item,index) in details">
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.package_credit.package_name }}</td>
                          <td class="text-center">
                            <span
                              v-if="!item.package_credit.package_item_amt || item.package_name === 'กำหนดเติมเอง(สำหรับลูกค้าเติมเอง)'">
                               {{ item.total }}
                            </span>
                            <span
                              v-if="item.package_credit.package_item_amt && item.package_name !== 'กำหนดเติมเอง(สำหรับลูกค้าเติมเอง)'">
                              {{ item.package_credit.package_item_amt * item.price_amt }}
                            </span>
                          </td>
                          <td class="text-center">
                            {{ item.total }}
                          </td>
                        </tr>
                        </tbody>
                        <tfoot class="mt-5">

                        <tr>
                          <th class="text-right" colspan="2">
                            รวม
                          </th>
                          <th class="text-center">
                            {{ sumary(1) }}
                          </th>
                          <th class="text-center">
                            {{ sumary(2) }}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="2"> </th>
                          <th class="text-center" >
                            รวมสุทธิ
                          </th>
                          <th class="text-center">
                            {{ sumary(2) }}
                          </th>
                        </tr>

                        </tfoot>
                      </table>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  v-if="package_pays && package_pays.length > 0"
                  cols="12" sm="12" md="12">
                  <v-card>
                    <v-card-text>
                      <h2>ตารางการผ่อนรำชะ</h2>
                      <table
                        class="mt-2 table">
                        <thead>
                        <tr>
                          <th class="text-left">งวด</th>
                          <th class="text-left">จำนวน</th>
                          <th>จำนวนเครดิตต่องวด</th>
                          <th>หลักฐานการโอน</th>
                          <th>ยอดเงินที่ชำระ</th>
                          <th>วันเวลาที่ชำระ</th>
                          <th>สถานะ</th>
                          <th>พนักงาน</th>
                          <th>เวลาดำเนินการ</th>
                          <th>เครื่องมือ</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,index) in package_pays">
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.total }}</td>
                          <td>{{ item.amount_credit }} (บาท)</td>
                          <td>
                            <span v-if="item.slip">
                            <a style="color:#0a6aa1;" target="_blank" title="กดเพื่อดูหลักฐานการโอน"
                               :href="item.slip"> กดเพื่อดูหลักฐานการโอน </a>
                              <i aria-hidden="true" class="fa fa-cloud-download"></i>
                            </span>
                            <span v-if="!item.slip">-</span>
                          </td>
                          <td>{{ item.amount_pay }}</td>
                          <td>

                            <span>{{ item.date_pay ? item.date_pay : '-' }} {{ item.time_pay }}</span>
                          </td>
                          <td>
                            <span v-if="item.confirm_pay === 'C'" style="color: rgb(227, 86, 83)">ยกเลิก</span>
                            <span v-if="item.confirm_pay === 'W' &&  !item.slip"
                                  style="color:rgb(227, 86, 83)">ค้างชำระ</span>
                            <span v-if="item.confirm_pay === 'W' &&  item.slip"
                                  style="color: #f0ad4e">รอการตรวจสอบ</span>
                            <span v-if="item.confirm_pay === 'Y'"
                                  style="color: #27AE60">ชำระเเล้ว</span>
                          </td>
                          <td>
                            {{ item.employee ? item.employee.employee_name : '-' }}
                          </td>
                          <td>
                            <span>{{ item.emp_date ? item.emp_date : '-' }} {{
                                item.emp_time
                              }}</span>
                          </td>
                          <td>

                            <span v-if="item.slip">

                              <v-btn
                                @click="approvePay(item)"
                                v-if="item.confirm_pay !== 'Y'"
                                color="primary"
                              >ยืนยัน</v-btn>


                              <v-btn
                                @click="cancelPay(item)"
                                v-if="item.confirm_pay !== 'Y'"
                                color="secondary"
                              >ยกเลิก</v-btn>
                            </span>


                            <v-btn
                              @click="deletePay(item.id)"
                              v-if="item.confirm_pay !== 'Y'"
                              color="danger"
                            >ลบ
                            </v-btn>

                            <!--                            <v-btn-->
                            <!--                              title="แจ้งชำระ"-->
                            <!--                              v-if="item.confirm_pay !== 'Y'"-->
                            <!--                              @click="show(item.mem_package_pay_id)"-->
                            <!--                              color="secondary"-->
                            <!--                            >แจ้งชำระอีกครั้ง</v-btn>-->


                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>


              <v-card-actions class="mt-5">
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <!--              <v-btn-->
                <!--                color="primary"-->
                <!--                text-->
                <!--                @click="save"-->
                <!--              >-->
                <!--                Save-->
                <!--              </v-btn>-->
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogCancel"
          max-width="500"
        >
          <!--          <template v-slot:activator="{ on, attrs }">-->
          <!-- <div class="d-flex justify-space-between"> -->
          <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
          <!--            <v-btn-->
          <!--              color="primary"-->
          <!--              dark-->
          <!--              class="mb-2"-->
          <!--              v-bind="attrs"-->
          <!--              v-on="on"-->
          <!--            >-->
          <!--              เพิ่มคะแนน-->
          <!--            </v-btn>-->
          <!-- </div> -->
          <!--          </template>-->
          <v-card>
            <v-card-title>
              <span class="text-h5">ยกเลิกการสั่งซื้อ</span>
            </v-card-title>

            <v-card-text>
              <!--                <h5>รายการสั่งซื้อแพ็กเกจ ชำระครบจำนวนแล้ว </h5>-->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-card>
                    <v-card-text>
                      <v-textarea
                        required
                        v-model="editedItem.confirm_pay_detail"
                        title="เหตุผล"
                        label="เหตุผล"
                        placeholder="เหตุผล"
                      ></v-textarea>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="closeCancel"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="cancelOrderConfirm"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<!--        <v-dialog-->
<!--          v-model="dialogDelete"-->
<!--          max-width="600px"-->
<!--        >-->
<!--          <v-card>-->
<!--            <v-card-title class="text-h5 mb-5">-->
<!--              Are you sure you want to delete this item?-->
<!--            </v-card-title>-->
<!--            <v-card-actions>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn-->
<!--                color="error"-->
<!--                outlined-->
<!--                @click="closeDelete"-->
<!--              >-->
<!--                Cancel-->
<!--              </v-btn>-->
<!--              <v-btn-->
<!--                color="primary"-->
<!--                @click="deleteItemConfirm"-->
<!--              >-->
<!--                OK-->
<!--              </v-btn>-->
<!--              <v-spacer></v-spacer>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
<!--        </v-dialog>-->
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.actions="{ item }">

      <v-btn
        @click="editItem(item)"
        color="secondary">
        ดูรายละเอียด
      </v-btn>
      <!--      <v-icon-->
      <!--        small-->
      <!--        class="mr-2"-->
      <!--        @click="editItem(item)"-->
      <!--      >-->
      <!--        {{ icons.mdiPencil }}-->
      <!--      </v-icon>-->
    </template>

    <template v-slot:item.ref="{ item }">
      <!--      <v-checkbox >-->
      <!--        item.package_status-->
      <!--        v-model="item.package_status"-->

      <a
        @click="editItem(item)">
        {{ item.mem_package_no }}
      </a>

      <!--      </v-checkbox>-->
    </template>
    <template v-slot:item.status="{ item }">
      <div style="display: inline">
        <span v-if="!item.date_pay && item.complete_status != 'C'" class="danger-badge"
                style="font-size: 13px">ค้างชำระ
        </span>
        <span v-if="item.date_pay && item.confirm_pay === 'Y'" class="success-badge"
                style="background-color:#4dbd74;font-size: 13px;color: black">ชำระเรียบร้อย
        </span>
        <span v-if="item.date_pay && item.confirm_pay === 'W'" class="warning-badge">
          รอการตรวจสอบ
        </span>
        <span v-if="item.date_pay && item.confirm_pay === 'C'" class="danger-badge">
          ยกเลิก
        </span>
      </div>
      <!--      </v-checkbox>-->
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_package_cash from '../../../services/packageCash'
import instance_package from "@/services/package";
import Swal from "sweetalert2";
// import instance_package from "@/services/package";

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,
    },
    total : 0,
    package_credits: [],
    details: [],
    package_pays: [],
    dialog: false,
    dialogDelete: false,
    dialogCancel: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      {text: 'อ้างอิง', value: 'ref'},
      {text: 'ชื่อร้าน', value: 'member.member_shop_name', sortable: false},
      {text: 'ประเภทการจ่าย', value: 'payment_type.name'},
      {text: 'วันที่สั่งซื้อ', value: 'can_date_time'},
      {text: 'สถานะ', value: 'status'},
      {text: 'จำนวน', value: 'amount'},
      {text: 'พนักงาน', value: 'employee.employee_name', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      price_start: 0,
      price_end: 0,
      score: 0,
    },
    defaultItem: {
      price_start: 0,
      price_end: 0,
      score: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'สั่งซื้อแพ็กเกจ เติมเงินเข้าระบบ' : 'สั่งซื้อแพ็กเกจ เติมเงินเข้าระบบ'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getPackageCredit()
      },
    },
    search : {
      handler(){
        this.getPackageCredit()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getPackageCredit()
  },

  methods: {
    sumary(type) {
      var sum = 0;
      // <span v-if="!item.package_credit.package_item_amt || item.package_name === 'กำหนดเติมเอง(สำหรับลูกค้าเติมเอง)'">
      //                          {{ item.total }}
      //                       </span>
      // <span v-if="item.package_credit.package_item_amt && item.package_name !== 'กำหนดเติมเอง(สำหรับลูกค้าเติมเอง)'">
      //                         {{ item.package_credit.package_item_amt * item.price_amt}}
      //                       </span>
      for (var i = 0; i < this.details.length; i++) {
        if (type == 1) {
          if (!this.details[i].package_credit.package_item_amt || this.details[i].package_name === 'กำหนดเติมเอง(สำหรับลูกค้าเติมเอง)') {
            sum += parseFloat(this.details[i].total)
          } else {
            sum += parseFloat(this.details[i].package_credit.package_item_amt * this.details[i].price_amt);
          }
        } else {
          sum += parseFloat(this.details[i].total);
        }
      }

      return (sum.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    },
    confirmOrder() {

      // this.editedIndex = this.packages.indexOf(item)
      // this.$router.push('login')
      // this.editedItem = {...item}
      var c = confirm('ยืนยันการสั่งซื้อ ใช่หรือไม่ ?')
      if (c) {
        // instance_package.confirmProductAmt(this.editedItem.mem_package_id).then(res => {
        //   this.dialogConfirm = true
        //   this.getReportPackage()
        //   this.close();
        // }).catch(err => {
        //
        // })
        this.$store.state.isLoading = true;
        instance_package_cash.confirmProductAmt(this.editedItem.id).then(res => {
          this.dialogConfirm = true
          this.$store.state.isLoading = false;
          this.getPackageCredit();
          setTimeout(function () {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
          },150);
          this.close()
        }).catch(err => {
          this.$store.state.isLoading = false;
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })

        })
      }
      // <!--                      {{url('admin/updateCreditProductAmtConfirm').'/'.$memberPackage->mem_package_id}}-->
    },
    cancelOrder() {
      // this.editedIndex = this.packages.indexOf(item)
      // this.$router.push('login')
      // this.editedItem = {...item}
      this.dialogCancel = true
      // instance_package_cash.cancelProductAmt(this.editedItem.id).then(res => {
      //
      //   this.dialogCancel = true
      // }).catch(err => {
      //
      // })

      // <!--                      เหตุผล confirm_pay_detail-->
    },
    editItem(item) {

      this.editedIndex = this.package_credits.indexOf(item)
      // this.$router.push('login')
      this.editedItem = {...item}
      console.log(item,123)
      instance_package_cash.getPackageDetail(item.id).then(res => {
        this.details = res.data.data;
        this.package_pays = res.data.mem_package_pays

        this.dialog = true
      }).catch(err => {

      })
    },

    cancelOrderConfirm() {
      // this.editedIndex = this.packages.indexOf(item)

      // this.editedItem = {...item}
      this.$store.state.isLoading = true;
      var data = {
        id: this.editedItem.id,
        confirm_pay_detail: this.editedItem.confirm_pay_detail
      }

      instance_package_cash.cancelProductAmt(data).then(res => {
        this.confirm_pay_detail = '';
        this.$store.state.isLoading = false;
        this.getPackageCredit()
          setTimeout(function () {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
          },150);
        this.closeCancel()
        this.close()
      }).catch(err => {
        this.$store.state.isLoading = false;
        Swal.fire({
          icon: 'error',
          title: err.message,
          showConfirmButton: false,
          timer: 1500
        })

      })

      // <!--                      เหตุผล confirm_pay_detail-->
      // <!--                      {{url('admin/updateCreditProductAmtCanceled').'/'.$memberPackage->mem_package_id}}-->
    },
    deleteItem(item) {
      this.editedIndex = this.points.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },


    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    approvePay(item) {

      this.editedIndex = this.packages.indexOf(item)
      // this.$router.push('login')
      this.editedItemPay = {...item}
      this.dialogApprove = true;
    },
    approvePayConfirm() {
      var data = {
        mem_package_pay_id: this.editedItemPay.mem_package_pay_id,
        mem_package_id: this.editedItemPay.mem_package_id,
        confirm_pay: 'Y',
        amount_credit: this.editedItemPay.amount_credit,
        confirm_pay_detail: this.editedItemPay.confirm_pay_detail,
      }

      // <!--                               action="{{url('admin/updateConfirmPay').'/'.$mem_package_pay->mem_package_pay_id}}" -->
      this.dialogApprove = false;
      instance_package_cash.approvePay(data).then(res => {

        // <!--    <a href="{{url('admin/updateConfirmPay').'/'.$mem_package_pay->mem_package_pay_id.'?confirm_pay=C'}}">-->
        // <!-- href="{{url('admin/memberPackagePayDelete').'/'.$mem_package_pay->mem_package_pay_id}}"-->
      }).catch(err => {

      })
      this.closeApprovePay();
    },
    cancelPay(item) {
      var c = confirm('ยกเลิก ใช่หรือไม่ ?')
      if (c) {
        this.$store.state.isLoading = true;
        instance_package_cash.cancelPay(item).then(res => {
          this.getPackageCredit()
          this.$store.state.isLoading = false
          this.closeDelete();
          setTimeout(function () {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
          },150)

          // <!--    <a href="{{url('admin/updateConfirmPay').'/'.$mem_package_pay->mem_package_pay_id.'?confirm_pay=C'}}">-->
        }).catch(err => {
          this.$store.state.isLoading = false
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })


        })
      }

    },
    deletePay(id) {

      var c = confirm('ลบ ใช่หรือไม่?')
      if (c) {
        this.$store.state.isLoading = true;
        instance_package_cash.deletePay(id).then(res => {
          this.getPackageCredit()
          this.$store.state.isLoading = false
          this.closeDelete();
          setTimeout(function () {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
          },150)

        }).catch(err => {
          this.$store.state.isLoading = false
          Swal.fire({
            icon: 'error',
            title: err.message,
            showConfirmButton: false,
            timer: 1500
          })

        })
        // <!-- href="{{url('admin/memberPackagePayDelete').'/'.$mem_package_pay->mem_package_pay_id}}"-->
      }
    },


    closeCancel() {
      this.dialogCancel = false
      this.confirm_pay_detail = '';
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    // save() {
    //   if (this.editedIndex > -1) {
    //     instance_point.pointUpdate(this.editedItem)
    //       .then(res => {
    //         Object.assign(this.points[this.editedIndex], this.editedItem)
    //       }).catch(err => {
    //
    //     });
    //   } else {
    //     instance_point.pointStore(this.editedItem)
    //       .then(res => {
    //         this.points.push(this.editedItem)
    //       }).catch(err => {
    //
    //     });
    //   }
    //   this.close()
    // },
    getPackageCredit() {
      this.$store.state.isLoading = true
      var data = {
        search : this.search,
        date: this.$route.query.date ? this.$route.query.date : '',
        status:this.$route.query.status ? this.$route.query.status : '',
        size : this.serverOptions.itemsPerPage,
        page : this.serverOptions.page,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }

      instance_package_cash.getReportPackageCredit(data).then(res => {
        this.package_credits = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {

        this.$store.state.isLoading = false
      });
    },
    getUser() {
      // eslint-disable-next-line no-useless-concat
      const baseURL = `${process.env.VUE_APP_BASEURL}` + '/login'
      instance
        .get(`${baseURL}user?search=`)
        .then(res => {
          if (res.status === 200) {
            this.users = res.data.data
          }

          // Unauthorized Logout
          // eslint-disable-next-line no-empty
          if (res.status === 200 && res.data.error === 'Unauthorized') {
            localStorage.removeItem('token_authentication')
          }
        })
        .catch(error => {
          console.log(error)

        })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
